html,
body,
#root {
  font-family: "lores-9-plus-narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(233, 233, 233);
}

code {
  font-family: "lores-9-plus-narrow", sans-serif;
}

a {
  pointer-events: all;
  color: rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: inherit;
}

body {
  overscroll-behavior: none;
  background: #efefef;
  font-family: "lores-9-plus-narrow", sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.390625vw);
  color: white;
}

.App-enter {
  color: #ffffff;
  font-family: "lores-9-plus-narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: calc(40px + 0.390625vw);
  align-items: center;
  height: 10%;
}

.App-description {
  color: #ffffff;
  font-family: "lores-9-plus-narrow", sans-serif;
  font-weight: 70;
  font-style: normal;
  font-size: calc(10px + 0.390625vw);
  align-items: center;
  height: 50%;
}

.App-link {
  color: #ffffff;
  font-family: "halyard-display", sans-serif;
  font-weight: 200;
  font-style: normal;
  height: 200%;
}

* {
  box-sizing: border-box;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.bg {
  background: linear-gradient(to top, #0a0a0a, #181a1a, #1d1d1f);
}

.notready {
  color: #606060;
}

.clicked {
  pointer-events: none;
  opacity: 0;
}

.imageWrapper {
  margin: 100px 100px;
}

.imageWrapper img {
  width: 400px;
  height: 400px;
}
